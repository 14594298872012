import { Table } from 'antd'
import React, { useState } from 'react'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <a>{text}</a>
  },
  {
    title: 'Age',
    dataIndex: 'age'
  },
  {
    title: 'Address',
    dataIndex: 'address'
  }
]

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park'
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park'
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park'
  },
  {
    key: '4',
    name: 'Disabled User',
    age: 99,
    address: 'Sidney No. 1 Lake Park'
  }
]

export const Feedback = (props) => {

  const [selectionType, setSelectionType] = useState('4');
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
     
      setSelectionType(`${selectedRowKeys}`);
    },
    getCheckboxProps: (record) => ({
      name: record.name
    })
  };
  return (
    <div>
      <Table
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [selectionType],
          ...rowSelection
          // onChange: () => {setSelectionType(selectedRowKeys)}
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};


export default Feedback